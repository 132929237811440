import dateFormat, { masks } from "dateformat";

export const formatDate = (date, format) => {
  return dateFormat(date, format);
}

export const showDate = (date) => {
  if(!date){
    return '';
  }
  if(typeof date.getMonth !== 'function'){
    date = new Date(Date.parse(date));
  }
  return formatDate(date, 'dd mmm yyyy');
}

export const showDateTime = (date) => {
  if(!date){
    return '';
  }
  if(typeof date.getMonth !== 'function'){
    date = new Date(Date.parse(date));
  }
  return formatDate(date, 'dd mmm yyyy HH:MM') + ' UTC';
}

export const showAddress = (address) => {
  return address;
}

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
}

export const showNumber = (number) => {
  return number;
}

export const showPercent = (value, precision = 4) => {
  return '' + (value*100).toFixed(precision) + '%';
}

export const showStrikePrice = (amount, currency) => {
  return showPrice(amount, currency);
  // return '' + amount + ' ' + currency?.toUpperCase();
}

export const roundDownAmount = (amount, currency, precision = 8) => {
  const finalPrecision = currency ? getPrecisionOnCurrency(currency) : precision;
  const factor = Math.pow(10, finalPrecision);
  const roundedValue = Math.floor(amount * factor) / factor;
  return formatNumberWithCommas(roundedValue.toFixed(finalPrecision));
};

export const showAmount = (amount, currency = null, precision = 8) => {
  const finalPrecision = currency ? getPrecisionOnCurrency(currency) : precision;
  const amountToFixed = Number(amount).toFixed(finalPrecision);
  return formatNumberWithCommas(amountToFixed);
};

export const showPrice = (amount, currency = null, precision = 8) => {
  if (currency)
    return showAmount(amount, currency, precision) + ' ' + currency?.toUpperCase();
  else
    return showAmount(amount, currency, precision);
}

export const showOption = (value, options) => {
  for(const op of options){
    if(op.value == value){
      return op.label;
    }
  }
  return null;
}

export const toDate = (dateStr) => {
  if(!dateStr || dateStr == ''){
    return null;
  }

  return new Date(Date.parse(dateStr));
}

export const fromDate = (date) => {
  if(!date){
    return '';
  }
  return '' + date.getFullYear() + '-' + (date.getMonth()+1).toString().padStart(2, '0')  + '-' + date.getDate().toString().padStart(2, '0'); 
}

export const ageFromDate = (dob) => {
  const month_diff = Date.now() - dob.getTime();
  const age_dt = new Date(month_diff); 
  const year = age_dt.getUTCFullYear();
  return Math.abs(year - 1970);
}

export const toUsdc = (currency) => { 
  if(!currency){
    return currency;
  }
  if(currency.toLowerCase() == 'usd' || currency.toLowerCase() == 'usdc'){
    return 'USDC';
  }
  return currency.toUpperCase();
}


// Utility function to format a number with thousands separators and trim trailing zeros
const formatNumberWithCommas = (value) => {
  const [integerPart, decimalPart] = value.toString().split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (decimalPart) {
    // Trim trailing zeros directly in the decimal part
    const trimmedDecimal = decimalPart.replace(/0+$/, "").replace(/\.$/, "");
    return trimmedDecimal ? `${formattedInteger}.${trimmedDecimal}` : formattedInteger;
  }
  return formattedInteger;
};

const getPrecisionOnCurrency = (currency) => {
  if (!currency) return 8;
  const lowerCurrency = currency.toLowerCase();
  const currencyPrecisionMap = {
    btc: 8,
    eth: 8,
    "us$": 2,
    usdt: 2,
    usdc: 2,
  };
  return currencyPrecisionMap[lowerCurrency] || 8;
};


